import '../styles/blog.css'

import * as React from 'react'

import { Link, graphql } from 'gatsby'

import { BlogPostsQuery } from '../../graphql-types'
import BlogSidebar from '../components/BlogSidebar'
import { fmtDateIso } from '../dates'
// import Container from '../components/Container'
// import IndexLayout from '../layouts'
// import Page from '../components/Page'

export const pageQuery = graphql`
  query BlogPosts {
    allMarkdownRemark(sort: { order: DESC, fields: frontmatter___date }) {
      nodes {
        id
        excerpt(pruneLength: 250)
        frontmatter {
          permalink
          date
          draft
          title
          subtitle
        }
      }
    }
  }
`
const PageTwo = ({ data }: { data: BlogPostsQuery }) => {
  const markdownNodes = data?.allMarkdownRemark?.nodes
  // console.log(Posts)
  return (
    <div className="blog">
      <div className="sidebar-and-article">
        <BlogSidebar />
        <div className="page-list blog-content">
          <h1>Posts</h1>
          <ul>
            {markdownNodes.map((node) => {
              const { date, permalink, title, draft } = node.frontmatter ?? {}
              return permalink && title && !draft ? (
                <li>
                  <Link to={permalink} key={permalink}>
                    <div className="list-item-title capitalize">{title}</div>
                    <div className="list-item-caption">{fmtDateIso(date)}</div>
                  </Link>
                </li>
              ) : null
            })}
          </ul>
        </div>
      </div>
    </div>
    // <IndexLayout>
    //   <Page>
    //     <Container>
    //     </Container>
    //   </Page>
    // </IndexLayout>
  )
}

export default PageTwo
